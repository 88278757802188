import { defineStore } from 'pinia'
import axios from 'axios'
import { apiURL } from '@/main'

export const useSmpEncashmentStatusStore = defineStore('smpEncashmentStatus', {
  state: () => ({
    smpEncashmentStatus: [],
    loading: false,
    error: null as string | null
  }),

  actions: {
    async fetchSmpEncashmentStatus() {
      this.loading = true
      this.error = null
      try {
        const response = await axios.get(apiURL + '/smpEncashmentStatus/')
        const items = await response.data
        Array.isArray(items) ? items : []
        this.smpEncashmentStatus = response.data
      } catch (error) {
        this.error = 'Failed to fetch encashment status'
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
})
