<template>
  <div>
    <h2 v-html="$t('search_smp_encashment_card_title')"></h2>
    <!--    Inkasso-->
    <v-select
      id="search_smp_encashment_rows_card_entity"
      v-model="smpEncashmentRowsStore.SmpEncashmentEntityIds"
      :label="$t('search_smp_encashment_rows_card_entity')"
      :items="smpEncashmentEntities"
      :item-title="entityName"
      item-value="id"
      clearable
      @keyup.enter="emitSearch"
      multiple
    />
    <!--    Status-->
    <v-select
      id="search_smp_encashment_card_status"
      v-model="smpEncashmentRowsStore.status"
      :label="$t('search_smp_encashment_card_status')"
      :items="smpEncashmentStatuses"
      :item-title="['name' + $route.params.upLang]"
      item-value="name"
      clearable
      @keyup.enter="emitSearch"
    />
    <!--    Kaskade vom-->
    <DbmMonthPicker v-model="smpEncashmentRowsStore.runDate" :dateLabel="$t('search_smp_encashment_card_cascade_date')" :clearable="true" />
    <!--    MPD Meldung von-->
    <DbmMonthPicker v-model="smpEncashmentRowsStore.productionValidOnMin" :dateLabel="$t('search_smp_encashment_card_mpd_date_from')" :clearable="true" />
    <!--    MPD Meldung bis-->
    <DbmMonthPicker
      v-model="smpEncashmentRowsStore.productionValidOnMax"
      :dateLabel="$t('search_smp_encashment_card_mpd_date_until')"
      :clearable="true"
      returnType="lastOfMonth"
    />
    <!--    Ist mind. 1. RE-->
    <v-select
      id="search_smp_encashment_card_min_invoice"
      v-model="smpEncashmentRowsStore.presentInEncashments"
      :label="$t('search_smp_encashment_card_min_invoice')"
      :items="invoiceOptions"
      :item-title="['name' + $route.params.upLang]"
      item-value="value"
      clearable
      @keyup.enter="emitSearch"
    />
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!--      Kaskadenlauf id-->
      <v-text-field
        id="search_smp_encashment_card_cascade_id"
        v-model="smpEncashmentRowsStore.runId"
        :label="$t('search_smp_encashment_card_cascade_id')"
        type="text"
        @keyup.enter="emitSearch"
      />
      <!--      Inkasso ID-->
      <!--todo adjust param smpEncashmentEntityIdent-->
      <v-text-field
        id="search_smp_encashment_card_encashment_id"
        v-model="smpEncashmentRowsStore.smpEncashmentRowId"
        :label="$t('search_smp_encashment_card_encashment_id')"
        type="text"
        @keyup.enter="emitSearch"
      />
      <!--      SMP Beitrag-->
      <!--      todo add privilege    v-if="!$privileges.has({ path: '/farms', permission: 'smpContribution' })"-->
      <v-select
        id="search_smp_encashment_card_assigned"
        v-model="smpEncashmentRowsStore.smpContribution"
        :label="$t('search_smp_encashment_card_contribution')"
        :items="smpContributionOptions"
        :item-title="['name' + $route.params.upLang]"
        item-value="value"
        clearable
        @keyup.enter="emitSearch"
      />
      <!--      MPD Menge von-->
      <v-text-field
        id="search_smp_encashment_card_mpd_quantity_from"
        v-model="smpEncashmentRowsStore.productionQuantityMin"
        :label="$t('search_smp_encashment_card_mpd_quantity_from')"
        type="text"
        @keyup.enter="emitSearch"
      />
      <!--      MPD Menge bis-->
      <v-text-field
        id="search_smp_encashment_card_mpd_quantity_until"
        v-model="smpEncashmentRowsStore.productionQuantityMax"
        :label="$t('search_smp_encashment_card_mpd_quantity_until')"
        type="text"
        @keyup.enter="emitSearch"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { useSmpEncashmentEntityStore } from '@/store/enums/SmpEncashmentEntityStore'
import { useSearchSmpEncashmentRowsStore } from '@/store/SearchSmpEncashmentRowsStore'
import { useSmpEncashmentStatusStore } from '@/store/enums/SmpEncashmentStatusStore'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'

/////  search card

const emit = defineEmits(['search'])

const emitSearch = () => {
  emit('search')
}

const expanded = ref(false)

/////  smpEncashmentRowsStore store

const smpEncashmentRowsStore = useSearchSmpEncashmentRowsStore()

/////  enums

const smpEncashmentEntityStore = useSmpEncashmentEntityStore()
const smpEncashmentStatusStore = useSmpEncashmentStatusStore()

const invoiceOptions = computed(() => {
  return [
    { id: 1, value: 'true', nameDe: 'Ja', nameFr: 'Oui', nameIt: 'Si' },
    { id: 2, value: 'false', nameDe: 'Nein', nameFr: 'Non', nameIt: 'No' }
  ]
})

const smpContributionOptions = computed(() => {
  return [
    { id: 1, value: 'yes', nameDe: 'ja', nameFr: 'oui', nameIt: 'sì' },
    { id: 2, value: 'no', nameDe: 'nein', nameFr: 'non', nameIt: 'no' },
    { id: 3, value: 'voluntary', nameDe: 'freiwillig', nameFr: 'volontaire', nameIt: 'volontario' },
    { id: 4, value: 'exempted', nameDe: 'ausgenommen', nameFr: 'exempt', nameIt: 'eccetto' }
  ]
})

interface Organisation {
  ident: number
  name: string
  displayName?: string
}

const smpEncashmentEntities = computed<Organisation[]>(() => {
  return smpEncashmentEntityStore.smpEncashmentEntities.map((org: Organisation) => ({
    ...org,
    displayName: `${org.ident} ${org.name} `
  }))
})
const smpEncashmentStatuses = computed(() => {
  return smpEncashmentStatusStore.smpEncashmentStatus.map((org: Organisation) => ({
    ...org,
    displayName: `${org.ident} ${org.name} `
  }))
})

const entityName = (item: Organisation) => {
  return item.displayName || item.name
}

onBeforeMount(() => {
  smpEncashmentEntityStore.fetchSmpEncashmentEntities()
  // todo check privileges
  smpEncashmentStatusStore.fetchSmpEncashmentStatus()
})
</script>
