import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchSmpEncashmentRowsStore = defineStore('SearchSmpEncashmentRowsStore', {
  state: () => {
    return {
      SmpEncashmentEntityIds: [] as number[],
      status: null as string | null,
      runDate: null as string | null,
      runId: null as string | null,
      smpEncashmentRowId: null as string | null,
      productionQuantityMin: null as string | null,
      productionQuantityMax: null as string | null,
      productionValidOnMin: null as string | null,
      productionValidOnMax: null as string | null,
      presentInEncashments: null as string | null,
      smpContribution: null as string | null
    }
  }
})
